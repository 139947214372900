<template>
  <div v-if="$store.state.languageList.length > 0">
    <div
      class="relative language-list"
    >
    <Menu>
      <span
        class="inline-block"
      >
        <MenuButton class="select-lang">
          <span
            v-if=" $store.state.LanguageId "
            class="block truncate text-16 uppercase font-livory-bold mr-3"
          >
            <span
              class="text-htz-steel font-thin normal-case hidden mr-2 lg:inline-block"
            >{{ $root.$t("loc_language") }}: </span>{{ $store.state.LanguageId }}
          </span>
          <span
            v-else
            class="block truncate text-16 uppercase font-livory-bold mr-3"
          >
            <span
              class="text-htz-steel font-thin normal-case hidden mr-2 lg:inline-block"
            >{{ $t("loc_language") }}: </span>{{ $store.state.LanguageId }}
          </span>

          <!--  <span class="arrow-down absolute right-0 flex items-center pr-2 pointer-events-none"></span> -->
        </MenuButton>
      </span>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          :class="` select-list-container`"
        >
          <MenuItems
            class="select-list"
          >
            <template
              v-for="item in selectList"
              :key="item"
              class="focus:outline-none"
              :value="item"
            >
              <div
                v-if="item.show"
              >
                <MenuItem
                  v-slot="{ active }"
                  :class="`select-list-item ${
                    sameLink(item.link) ? 'text-htz-darkgrey' : 'text-htz-steel'
                  } ${active ? 'text-htz-red' : ''}`"
                >
                  <VirtualLink
                    :href="item.link"
                    :class-list="`${
                      sameLink(item.link) ? 'font-semibold current-url' : 'font-normal'
                    } block truncate text-htz-steel`"
                    :refresh-page="true"
                    close-menu-on-click="true"
                  >
                  <span :class="active ? 'text-htz-red' : 'text-htz-darkgrey'">
                    {{ item.name }}
                  </span>
                  </VirtualLink>
                </MenuItem>
              </div>
            </template>
          </MenuItems>
        </div>
      </transition>
    </Menu>
  </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";


import axiosInstance from "@/services/http-common";
import {setStoreModule} from "@/store/store.utils";
import {mapActions} from "vuex";
export default {
  name: "SelectItem",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  props: {
    selectData: {
      type: Object,
      default: () => ({}),
    },
    initLang: {
      type: String,
      default: "",
    },
    dropPosition: {
      type: String,
      default: "",
    },
  },
  computed: {
    selectList() {
      return [
        {shortName: '', link: '/'},
        ...this.$store.state.languageList.map((o) => ({
          name: o.LanguageTitle,
          shortName: o.LanguageID,
          link: o.SefUrl,
          show: o.DoNotExistOnLanguage !== 'True',
        })),
      ]
    }
  },
  methods: {
    ...mapActions(['makeRequest', 'setModuleInfo']),
    sameLink(val) {
      return val === location.pathname
    },
    navigate(val) {
      axiosInstance.post(
        '/api/entities/custom/pagedata',
        {
          "EntityTypeID": "46",
          "PageID": "102513",
          "ModulePreviewMode": false
        }
      ).then(res => {
        res.data.ModuleData.forEach(m => {
          setStoreModule(m.Module.EntityID.toString());
          this.setModuleInfo({moduleInfo: m, query: this.$route.query})
          if (m.Module.Api.RequestBody.EntityTypeID) {
            this.makeRequest(
              {
                moduleInfo: m,
              })
          } else {
            this.$store.commit('loadingFinished', {
              id: m.Module.EntityID.toString()
            })
          }
        })
        this.$store.state.PageData.ModuleData = res.data.ModuleData
      })
    }
  },
  /*setup(props) {
    if (props.selectData.length) {
      let selectList = [];
      let obj;
      for (const value of props.selectData) {
        obj = Object.assign({}, {name: value.LanguageTitle, shortName: value.LanguageID, link: value.SefUrl});
        selectList.push(obj);
      }
      //add one empty object to array (it holds an expression $t("loc_map_region_choose"))
      obj = Object.assign({}, {shortName: props.initLang});
      selectList.unshift(obj);

      const selectList = [
        {shortName: props.initLang, link: '/'},
        ...props.selectData.map((o) => ({
          name: o.LanguageTitle,
          shortName: o.LanguageID,
          link: o.SefUrl,
        })),
      ];
      return {
        selectList,
      };
    }
  },*/
};
</script>

<style lang="scss">
.select-list-container {
  @apply  left-0 right-0 fixed z-10 overflow-y-auto bg-white;
  top: 64px;
  max-height: calc(100vh - 64px);
  @screen lg {
    @apply absolute top-auto right-0 left-auto w-auto min-w-250 rounded-md shadow-lg mt-0;
  }
}
.select-list {
  @apply max-h-600 py-2 shadow-xs overflow-auto;
  &:focus,
  &:active {
    @apply outline-none;
  }
  li:first-child {
    @apply hidden;
  }

}
.current-url {
  @apply text-htz-darkgrey font-livory-bold;
}
.select-list-item {
  @apply text-base font-livory cursor-pointer select-none relative py-3 px-3;
  &:focus,
  &:active,
  &:focus-visible {
    @apply outline-none text-htz-red;
  }
  &:hover {
      @apply text-htz-red;
    }
  a {
    @apply text-base font-livory py-3 px-3;
    &:hover {
      @apply text-htz-red;
    }
  }
}
.select-lang {
  @apply transition-all duration-300 ease-in-out;
  span{
    @apply transition-all duration-300 ease-in-out;
  }
  &:hover span {
    @apply text-htz-red;
  }
  &:focus-visible {
    transition: none !important;
  } 
}

.btn-small:after {
  right: 8px;
}
</style>
