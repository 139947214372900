<template>
    <div
     v-if="hasPropertyValue('Title',moduleInfo.Module.ModuleProperties)"
      class="title-big content relative z-10 pb-4 lg:pb-16"
      :class="moduleInfo.Module.CssClass"
    >
      <div class="max-w-screen-3xl mx-auto relative">
        <div class="page-title-container-v1">
          <div class="white-block" />
          <div class="max-w-screen-xl mx-auto">
            <h2

                :class="moduleInfo.Module.ModuleProperties.ModuleHasSmallerFont === 'True' ? 'title-2a title-2a--red' : 'title-1--red'"
                class="card-headline mt-8 mb-4"
            >
                {{ getPropertyValue($t,"Title",moduleInfo.Module.ModuleProperties) }}
            </h2>

          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import {selfDataRequestMixin} from "@/mixins";
  import "@/css/modules/_tooltip.scss";


  export default {
    name: "TitleRed",
    mixins: [selfDataRequestMixin],
    props: {
      moduleInfo: {
        type: Object,
        default: () => ({}),
      },
    }
  };
  </script>
