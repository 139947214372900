<template>
  <div
    class="relative inline-block text-left"
  >
    <Menu>
      <span class="rounded-md block lg:inline w-full lg:w-auto">
        <MenuButton
          class="menu-button w-full lg:w-auto lg:flex relative"
          data-cy="userAccountDropdown"
        >
          <i class="icon icon-user-tools-ico text-htz-steel text-40 mx-2 hover:text-htz-red "><span class="path1" /><span class="path2" /></i>
          <span
            v-if="favorites.length > 0"
            class="rounded-full bg-htz-red text-white text-xs w-4 h-4 absolute top-0 left-0"
          >{{ favorites.length }}</span>
        </MenuButton>
      </span>

      <transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute user-account-dropdown z-10 right-0 bottom-0 lg:bottom-auto w-56 mt-2 mb-16 lg:mb-0 origin-bottom-right lg:origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
        >
          <div
            v-if="loggedIn"
            data-cy="userLoggedIn"
            class="px-4 py-3"
          >
            <p class="text-sm leading-5">
              Signed in as
            </p>
            <p class="text-sm font-medium leading-5 text-gray-900 truncate">
              {{ loggedInEmail }}
            </p>
          </div>

          <div
            v-if="gotMenuData.length > 0"
            class="py-1"
          >
            <MenuItem ref="close">
              <div />
            </MenuItem>
            <template
              v-for="menuItem in filterMenu('MENU_USER_OPTIONS')"
              :key="menuItem.EntityID"
            >
              <MenuItem
                v-if="menuItem.MenuItemType === 'FAVORITES'"
                v-slot="{ active }"
              >
                <VirtualLink
                  :href="menuItem.Children.length > 0 ? 'javascript:void(0);' : (menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl)"
                  :class-list=" (active ? 'bg-gray-100 text-htz-red' : 'text-htz-darkgrey') + ' ' + 'flex justify-between items-center w-full px-4 py-3 text-base leading-5 text-left' "
                  close-menu-on-click="true"
                  @onClick="onVirtualClick"
                >
                  <span> {{ menuItem.Name }} </span>
                  <span class="flex items-center">
                    <i
                      class="icon icon-heart-ico text-htz-red text-24 mr-2"
                      :class="{'heart-active': favorites.length > 0}"
                    ><span class="path1" /><span class="path2" /></i>
                    <span
                      class="font-livory-bold"
                      :class="{'text-htz-red': favorites.length > 0}"
                    >{{ favorites.length }}</span>
                  </span>
                </VirtualLink>
              </MenuItem>

              <!-- TODO: kad se implementira itineraries, zavrsiti ovaj dio -->
              <MenuItem
                v-if="menuItem.MenuItemType === 'ITINERARY'"
                v-slot="{ active }"
              >
                <a
                  :href="menuItem.Children.length > 0 ? 'javascript:void(0);' : (menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl)"
                  :class="
                    active ? 'bg-gray-100 text-htz-red' : 'text-htz-darkgrey'
                  "
                  class="flex justify-between w-full px-4 py-3 text-base leading-5 text-left"
                >
                  <span>{{ menuItem.Name }}</span>
                  <span class="flex items-center">
                    <i
                      class="icon icon-heart-ico text-htz-red text-24 mr-2"
                    ><span class="path1" /><span class="path2" /></i>
                    <span class="font-livory-bold">1</span>
                  </span>

                </a>
              </MenuItem>

              <MenuItem
                v-else-if="menuItem.MenuItemType === '' "
                v-slot="{ active }"
                class="border-t border-htz-lightgrey"
              >
                <a
                  :href="menuItem.Children.length > 0 ? 'javascript:void(0);' : (menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl)"
                  :class="
                    active ? 'bg-gray-100 text-htz-darkgrey' : 'text-htz-red'
                  "
                  class="dropdown-link flex justify-between w-full px-4 py-4 text-base leading-5 text-left hover:text-htz-red"
                >
                  {{ menuItem.Name }}
                </a>
              </MenuItem>
              <template v-if="!loggedIn">
                <MenuItem
                  v-if="menuItem.MenuItemType === 'REGISTER'"
                  v-slot="{ active }"
                  class="border-t border-htz-lightgrey"
                >
                  <a
                    :class="
                      active ? 'bg-gray-100 text-htz-darkgrey' : 'text-htz-red'
                    "
                    class="dropdown-link flex justify-between w-full px-4 py-4 text-base leading-5 text-left hover:text-htz-red cursor-pointer"
                    @click.prevent="onRegisterStart(menuItem)"
                  >
                    {{ menuItem.Name }}
                  </a>
                </MenuItem>
                <MenuItem
                  v-if=" menuItem.MenuItemType === 'LOGIN'"
                  v-slot="{ active }"
                  data-cy="userLogin"
                  class="border-t border-htz-lightgrey"
                >
                  <a
                    :class="
                      active ? 'bg-gray-100 text-htz-darkgrey' : 'text-htz-red'
                    "
                    class="dropdown-link flex justify-between w-full px-4 py-4 text-base leading-5 text-left hover:text-htz-red cursor-pointer"
                    @click.prevent="onLoginStart(menuItem)"
                  >
                    {{ menuItem.Name }}
                  </a>
                </MenuItem>
              </template>
              <MenuItem
                v-if="loggedIn && menuItem.MenuItemType === 'USER_DASHBOARD'"
                v-slot="{ active }"
                data-cy="userLogout"
                class="border-t border-htz-lightgrey"
              >
                <a
                  :class="
                    active ? 'bg-gray-100 text-htz-darkgrey' : 'text-htz-red'
                  "
                  class="dropdown-link flex justify-between w-full px-4 py-4 text-base leading-5 text-left hover:text-htz-red cursor-pointer"
                  @click.prevent="onDashboardStart(menuItem)"
                >
                  {{ menuItem.Name }}
                </a>
              </MenuItem>
            </template>
            <template v-if="loggedIn">

              <MenuItem
                v-slot="{ active }"
                data-cy="userLogout"
                class="border-t border-htz-lightgrey"
              >
                <a
                  :class="
                    active ? 'bg-gray-100 text-htz-darkgrey' : 'text-htz-red'
                  "
                  class="dropdown-link flex justify-between w-full px-4 py-4 text-base leading-5 text-left hover:text-htz-red cursor-pointer"
                  @click.prevent="onLogOutStart()"
                >
                  {{ $t('loc_login_button_logout') }}
                </a>
              </MenuItem>
            </template>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { menuMixin } from "@/mixins";
import {mapGetters} from "vuex";
import axiosInstance from "@/services/http-common";
import prepareImages from "@/components/utils/prepareImages";
import {GalleryResponseInterface} from "@/interfaces";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  mixins: [menuMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      //loggedIn: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: 'LoggedInUserModule/loggedIn',
      loggedInEmail: 'LoggedInUserModule/loggedInEmail',
    }),
    favorites() {
      return this.$store.state.favorites
    }
  },
  methods: {
    onLoginStart(menuItem) {
      this.$store.dispatch('LoggedInUserModule/startLogin', menuItem);
    },
    onRegisterStart(menuItem) {
      this.$store.dispatch('LoggedInUserModule/startRegistration', menuItem);
    },
    onLogOutStart() {
      this.$store.dispatch('LoggedInUserModule/logout');
    },
    onDashboardStart(menuItem) {
      this.$store.dispatch('LoggedInUserModule/dashboard', menuItem);
    },
    onVirtualClick() {
      if (this.$refs.close) {
        console.log(this.$refs.close);
        this.$refs.close.$el.click()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-link{
    @apply relative inline-flex items-center justify-between py-4 px-4 transition-all duration-300 ;
    &:after{
        @apply transition-all duration-300 w-0 h-0 absolute left-auto;
        content: "";
        right: 16px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid theme('colors.htz-red');
    }
    &:hover{
        @apply text-htz-red;
    }
    &:focus,
    &:active{
        @apply outline-none shadow-none;
    }
}
button:focus{outline:none;}

.icon-heart-ico.heart-active {
    .path1:before{
        @apply text-htz-red;
    }
}
.user-account-dropdown {
  &:focus-visible{
      outline: none !important;
  }
}
</style>
