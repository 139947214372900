<template>
  <p
    v-if="gotBreadcrumbsData"
    :class="moduleClass"
  >
    <VirtualLink
      v-if="gotHpHyperlinkData != null"
      :href="gotHpHyperlinkData.SefUrl"
    >
      <span>
        {{ gotHpHyperlinkData.BreadcrumbText }}
      </span>
    </VirtualLink>
    <VirtualLink
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.IDObjekta"
      :href="breadcrumb.SefUrl"
    >
      <span>
        {{ breadcrumb.BreadcrumbText }}
      </span>
    </VirtualLink>
  </p>
  <JsonLd
    v-if="gotBreadcrumbsData"
    :json="jsonSchema"
    :script-id="'breadcrumbsList'"
  />
</template>

<script>
import axiosInstance from "@/services/http-common.js";
import { logoHyperlink } from "@/mixins";
import JsonLd from "@/components/utils/json-ld";
import { isEqual } from "lodash";

export default {
  components: { JsonLd},
  mixins: [logoHyperlink],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
    moduleClass: {
      type: String,
      default: () => ('breadcrumbs text-htz-red'),
    },
  },
  data() {
    return {
      breadcrumbs: [],
      jsonSchema: {}
    }
  },
  computed: {
    gotBreadcrumbsData: function () {
      if (this.breadcrumbs.length > 0) {
        this.createJsonSchema();
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.getBreadcrumbsData();
  },
  methods: {
    getBreadcrumbsData() {
      //promjenjena logika za "show/hide"
    if(this.moduleInfo.Module.ModuleProperties.ModuleHideBreadcrumbs !== "True"){
        const entityTypeID = this.$store.state.PageData.Template.IDTipaObjekta;
        this.callBreadcrumbsApi(entityTypeID, this.$store.state.PageData.PageId, true);
      }
    },
    callBreadcrumbsApi(entityTypeID, entityId, firstCall){
      let typeId = entityTypeID;
      const hpObjectID = parseInt(this.$store.state.Params.find(o => o.Key === 'HomePageObjectId')?.Value || '', 10);
      if(hpObjectID !== entityId) {
        axiosInstance
          .post(this.$store.state.CdnApiBaseUrl + "/api/breadcrumbs", {
            Settings: this.$store.state.CdnApiKey,
            EntityID: entityId,
            LanguageID: this.$store.state.PageData.LanguageId,
          })
          .then((response) => {
            const res = response.data;
            res.map((obj) => {
              if(obj.IDObjekta === entityId && obj.ParentEntityTypeID > 0) {
                typeId = obj.ParentEntityTypeID;
              }
              this.breadcrumbs.unshift(obj);
            });
            if(typeId != 0 && firstCall){
                const currentEntity = this.$store.state.EntityDefaultParent.find(obj => obj.EntityTypeID === typeId);
                if(currentEntity !== undefined){
                  const parentEntity = this.$store.state.EntityDefaultParent.find(obj => obj.EntityTypeID == currentEntity.ParentTypeID);
                  const parentId = currentEntity.ParentID != 0 ? currentEntity.ParentID : parentEntity.ParentID;
                  if(parentId != 0){
                    this.callBreadcrumbsApi(0, parentId, false);
                  }
                }
              }
          })
          .catch((e) => {
            this.error = e;
          });
      }
    },
    createJsonSchema(){
      let itemListElement = [];
      if(this.breadcrumbs.length > 0){
        let breadcrumbsJson = this.breadcrumbs;
        if(this.gotHpHyperlinkData != null){
          itemListElement.push({
              "@type": "ListItem",
              "position": 1,
              "name": this.gotHpHyperlinkData.BreadcrumbText,
              "item": window.location.origin + this.gotHpHyperlinkData.SefUrl
            });
        }
        breadcrumbsJson.map((obj, i) => {
            itemListElement.push(
              {
              "@type": "ListItem",
              "position": this.gotHpHyperlinkData != null ? i + 2 : i + 1,
              "name": obj.BreadcrumbText,
              "item": window.location.origin + obj.SefUrl
            }
            );
          });
          this.jsonSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": itemListElement
          }
      }
    }
  }
};
</script>
