<template>
  <div class="action-buttons max-w-screen-xl mx-auto lg:space-x-6 px-6">
    <ShareBtn
      v-if="$store.state.Params.find(o => o.Key === 'SocialShareServices')"
      :module-info="moduleInfo"
      :module-class="''"
      :module-location="'loc-footer'"
    />
    <Favorite
      :entity-type="pageEntity.EntityType"
      :entity="pageEntity"
      :inline="true"
    />
  </div>
</template>

<script>

import ShareBtn from "@/components/includes/ShareBtn";
import Favorite from "@/components/includes/Favorite";

export default {
   components: {
     Favorite,
    ShareBtn
  },
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
     pageEntity() {
       return {
         EntityID: this.$store.state.PageData.PageId,
         SEO: this.$store.state.PageData?.Seo,
         Title: this.$store.state.PageData?.Seo?.PageTitle || '',
         EntityType: this.$store.state.PageData?.ModuleData?.[0]?.Module?.Binding?.SourceName || 'Page',
       }
     }
  }
};
</script>
