<template>
  <i
    v-if="googleSearchUrl"
      class="icon icon-search-ico text-htz-steel hover:text-htz-red text-40"
      @click="openModal(googleSearchUrl)"
      @keyup.enter="openModal(googleSearchUrl)"
      tabindex="0"
    />
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" :open="isOpen" @close="closeModal">
      <div
        class="dialog-container fixed inset-0 z-50 overflow-y-auto"
        :class="{
          'dialog-has-covid-track':
            hasCovidTrack === true || hasCovidTrack === null,
        }"
      >

        <div class="dialog-container-inner overflow-y-hidden">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="dialog-overlay fixed inset-0"
              :class="{
                'dialog-has-covid-track':
                  hasCovidTrack === true || hasCovidTrack === null,
              }"
            />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
          
            <div class=" dialog-content-container inline-block w-full h-full p-6 overflow-hidden overflow-y-auto transition-all transform bg-htz-blue bg-opacity-90 shadow-xl pt-0 ">
              
              <div class="max-w-screen-2xl mx-auto w-full">
                
                <button
                  type="button"
                  class="text-white text-68 cursor-pointer float-right block leading-none md:mr-4 h-10"
                  style="outline: none !important"
                  @click="closeModal"
                >
                  ×
                </button>
                
              </div>
              <h3 class="subtitle-2 max-w-screen-lg mx-auto pl-3 pt-6 search-title">
                  {{ $t('loc_search_title') }}
                </h3>
              <div class="clear-both" />
              <DialogTitle
                as="p"
                class="text-2xl font-lg leading-6 text-htz-grey w-full max-w-screen-lg mx-auto"
                style="color: #c8c8c8"
              >
                <div id="google-search"></div>
              <div class="gcse-search"></div>
              </DialogTitle>
              <!-- SEARCH RESULTS -->
              <!-- <div class="max-w-screen-lg mx-auto mt-10">
                <p
                  class="
                    text-2xl
                    font-lg
                    leading-6
                    text-htz-grey
                    w-full
                    max-w-screen-lg
                    mx-auto
                  "
                  style="color: #c8c8c8"
                >
                  Search results for:
                </p>
                <p class="text-htz-red text-48 mt-3">Zlatna bula</p>

                <div class="search-results-container mx-auto">
                  <div
                    class="
                      search-result
                      border-b border-white border-opacity-25
                      py-6
                    "
                  >
                    <span class="card-overtitle text-white"> history </span>
                    <h3 class="subtitle-1 text-white">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are
                      located off the southwest coast of the island of Hvar,
                      Croatia, opposite the entrance to the Hvar (city) harbour.
                    </p>
                  </div>

                  <div
                    class="
                      search-result
                      border-b border-white border-opacity-25
                      py-6
                    "
                  >
                    <span class="card-overtitle text-white"> history </span>
                    <h3 class="subtitle-1 text-white">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are
                      located off the southwest coast of the island of Hvar,
                      Croatia, opposite the entrance to the Hvar (city) harbour.
                    </p>
                  </div>

                  <div
                    class="
                      search-result
                      border-b border-white border-opacity-25
                      py-6
                    "
                  >
                    <span class="card-overtitle text-white"> history </span>
                    <h3 class="subtitle-1 text-white">Zlatna bula</h3>
                    <p class="" style="color: #cecece">
                      The Pakleni or sometimes referred as Paklinski islands are
                      located off the southwest coast of the island of Hvar,
                      Croatia, opposite the entrance to the Hvar (city) harbour.
                    </p>
                  </div>

                  <button class="btn-transparent mx-auto flex mt-6">
                    More results
                  </button>
                </div>
              </div> -->
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { swiperListOptions } from "@/services/swiperOptions";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  props: {
    hasCovidTrack: {
      type: Boolean,
      default: false,
    },
    googleSearchUrl: {
      type: String,
      default: "",
    },
    test: {
      type: String,
      default: "",
    }
  },
/*  setup() {
   // const isOpen = ref(false);

    return {
      isOpen,
      closeModal() {
        let searchModal = document.querySelector(".dialog-container");
        if (searchModal) {
          searchModal.classList.remove("dialog-container-fixed");
        }
        isOpen.value = false;
      },
      openModal(googleSearchUrl) {
        isOpen.value = true;
        let navHeight = document.querySelector(".nav").offsetHeight;
        if (navHeight < 66) {
          setTimeout(() => {
            let searchModal = document.querySelector(".dialog-container");
            if (searchModal) {
              searchModal.classList.add("dialog-container-fixed");
            }
          }, 50);
        }
        setTimeout(()=> {
          this.onstart(googleSearchUrl);
        }, 100)
      },
      onstart(googleSearchUrl) {
        console.log('onStart');
        console.log(this.googleSearchUrl);
        let script = document.createElement("script");
          script.setAttribute("type", "text/javascript");
          script.setAttribute("src", googleSearchUrl?.Value);
          document.body.appendChild(script);
      },
    };
  },*/
  data() {
    return {
      swiperListOptions: { ...swiperListOptions },
      showCovidTrack: sessionStorage.getItem("showCovidTrack"),
      isOpen: false,
    };
  },
  methods: {
    closeModal() {
      let searchModal = document.querySelector(".dialog-container");
      if (searchModal) {
        searchModal.classList.remove("dialog-container-fixed");
      }
      this.isOpen = false;
    },
    openModal(googleSearchUrl) {
      this.isOpen = true;
      let navHeight = document.querySelector(".nav").offsetHeight;
      if (navHeight < 66) {
        setTimeout(() => {
          let searchModal = document.querySelector(".dialog-container");
          if (searchModal) {
            searchModal.classList.add("dialog-container-fixed");
          }
        }, 50);
      }
      setTimeout(()=> {
        this.onstart(googleSearchUrl);
      }, 500)
    },
    onstart(googleSearchUrl) {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", googleSearchUrl?.Value);
      document.body.prepend(script);
    },
  },
};
</script>

<style lang="scss" scoped>
//dialog
.dialog-container,
.dialog-overlay {
  top: 64px;
  @screen lg {
    top: 120px;
  }
}

.dialog-container.dialog-has-covid-track.dialog-container-fixed {
  top: 104px;
}

.dialog-overlay.dialog-has-covid-track,
.dialog-container.dialog-has-covid-track {
  top: 104px;
  @screen lg {
    top: 160px;
  }
}

.dialog-container-inner,
.dialog-content-container {
  height: 100%;
  min-height: calc(100vh - 64px);
  @screen lg {
    min-height: calc(100vh - 120px);
  }
}

.dialog-container-fixed {
  @screen lg {
    top: 64px;
  }
  .dialog-container-inner,
  .dialog-content-container {
    @screen lg {
      min-height: calc(100vh - 64px);
    }
  }
}
.dialog-has-covid-track{
  .dialog-container-inner,
  .dialog-content-container {
    min-height: calc(100vh - 104px);
    @screen lg {
      min-height: calc(100vh - 160px);
    }
  }
}
//search
.icon-search-container:hover {
  .icon-search-ico:before {
    @apply text-htz-red;
  }
}
.icon-search-ico:hover {
  &:before {
    @apply text-htz-red;
  }
}
button:focus {
  @apply outline-none;
}

//tab bar
.tab-nav-slider-container {
  @apply min-h-20 border-t border-b border-white border-opacity-25;
  @screen md {
    @apply min-h-24;
  }
  .tab-nav-slider {
    @apply mx-auto min-h-20  max-w-screen-xl min-h-20  flex items-center justify-center flex-wrap py-3 transition-all duration-300 ease-in-out;
    @screen sm {
      @apply min-h-24;
    }
    li a {
      @apply w-full h-full block text-white uppercase text-14 font-livory-bold px-4 transition-all duration-100 ease-in-out;
      letter-spacing: 1px;
      @screen sm {
        @apply text-15;
      }
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
      }
      &:hover,
      &:active {
        @apply text-htz-red;
      }
    }
    li a.active {
      @apply text-htz-red;
      &:after {
        content: "";
        @apply bg-htz-red;
      }
    }
  }
  .tab-nav-slider-container-inner:not(.tab-nav-slider-fixed) {
    li a {
      @apply text-white;
    }
    li a.active {
      @apply text-htz-red;
      &:after {
        content: "";
        @apply bg-htz-red;
      }
    }
    /* &:first-child li a{
        @apply text-htz-red;
    } */
  }

  .tab-nav-slider-fixed {
    @apply fixed z-30 left-0 right-0;
    top: 64px;
  }
}
.search-title {
  color: #c8c8c8;
  @apply text-16;
  @screen md{@apply text-24;}
}
</style>
