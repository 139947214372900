<template>
  <div>
    <footer class="footer-container max-w-screen-3xl mx-auto bg-white">
      <i
        class="icon icon-cookie_icon_bg cookie-consent cursor-pointer"
        @click="openCookieConsent()"
      >
        <span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" />
      </i>
      <div class="footer-big container max-w-screen-2xl mx-auto">
        <FooterActionButtons
          v-if="gotMenuData.length > 0"
          :module-info="filterMenu('FOOTER_MENU')"
        />
      </div>
      <div class="module-border container max-w-screen-2xl mx-auto mt-8 mb-6" />
      <div class="footer-big container max-w-screen-xl mx-auto lg:px-6">
        <FooterMenuSocialIcons
          v-if="gotMenuData.length > 0"
          :module-info="filterMenu('ICONS')"
        />
        <FooterMenuMain
          v-if="gotMenuData.length > 0"
          :module-info="filterMenu('FOOTER_MENU')"
        />
        <div class="flex items-start justify-start flex-col md:flex-row">
          <FooterMenuNewsletter
            v-if="gotMenuData.length > 0"
            :module-info="filterMenu('FOOTER_MENU_NEWSLETTER').slice(0, 2)"
          />
          <!--TODO implementacija newsletter forme -->
          <div
            v-if="false"
            class="flex flex-col content-start items-center md:items-end w-full lg:w-6/12 pb-5 py-5 lg:pt-0"
          >
            <div class="flex md:max-w-450 flex-wrap content-center ">
              <h3 class="overtitle-1 w-full md:text-15 text-htz-darkgrey text-center md:text-left pb-5">
                Subscribe to our newsletter
              </h3>
              <div class="flex justify-center md:justify-start w-full">
                <input
                  id="subscribe"
                  type="text"
                  name="subscribe"
                  class="w-full border border-htz-steel min-h-60 px-4"
                  placeholder="Enter your email"
                >
                <a
                  href="#"
                  class="btn-grey min-w-32 lg:min-w-40"
                >Subscribe</a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <FooterMenuApp
            v-if="gotMenuData.length > 0"
            :module-info="filterMenu('FOOTER_APP')"
          />
        </div>
      </div>
      <div class="module-border container max-w-screen-2xl mx-auto mt-8 mb-6" />
      <div class="footer-big container max-w-screen-xl mx-auto lg:px-6">
        <div class="flex flex-col items-start justify-between">
          <FooterMenuLogos
            v-if="gotMenuData.length > 0"
            :module-info="filterMenu('FOOTER_LOGOS')"
          />
        </div>
      </div>
      <FooterMenuBottom
        v-if="gotMenuData.length > 0"
        :module-info="filterMenu('FOOTER_BOTTOM')"
      />
      <!--PARAMS here -->
    <template v-if="orgSnippet">
      <JsonLdManager
        :scheme-data="orgSnippet"
        :scheme-type="'Organization'"
        :script-id="'organizationSnippet'"
      />
    </template>
    </footer>
    <!-- <CookieMsg /> -->
  </div>
</template>

<script>

import "@/css/modules/_footer.scss";
import FooterMenuMain from "./footer/FooterMenuMain";
import FooterMenuNewsletter from "./footer/FooterMenuNewsletter";
import FooterMenuBottom from "./footer/FooterMenuBottom";
import FooterMenuSocialIcons from "./footer/FooterMenuSocialIcons";
import FooterMenuLogos from "./footer/FooterMenuLogos";
import FooterMenuApp from "./footer/FooterMenuApp";
import FooterActionButtons from "./footer/FooterActionButtons";
import JsonLdManager from "@/components/utils/json-ld-manager";
// import CookieMsg from "./CookieMsg.vue";

import { menuMixin } from "@/mixins";

export default {
  name: "Footer",
  components: {
    FooterMenuMain,
    FooterMenuNewsletter,
    FooterMenuBottom,
    FooterMenuSocialIcons,
    FooterMenuApp,
    FooterMenuLogos,
    FooterActionButtons,
    JsonLdManager,
    // CookieMsg
},
  mixins: [menuMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data(){
    return{
      orgSnippet: undefined
    }
  },
  computed: {
    getParams(){
        return this.$store.state.Params;
    },
  },
  watch:{
    getParams() {
      this.setParams();

    }
  },
  methods: {
    setParams(){
      this.orgSnippet = JSON.parse(this.$store.state.Params.find(o => o.Key === 'OrganizationSnippet')?.Value || "{}");
    },

    openCookieConsent() {
      window.CookieControl.open();
      setTimeout(() => {
        let elementChecked = document.querySelectorAll('#ccc .checkbox-toggle--slider input:checked')
        elementChecked.forEach(el => el.parentNode.closest('.checkbox-toggle--slider').classList.add('red'))
        document.getElementById('ccc-reject-settings').addEventListener('click', function() {
          location.reload();
      });
      }, 100);
    }
  }
};
</script>
