<template v-if="Object.entries(image).length">
  <!-- if media gallery type is video -->
  <template
    v-if="
      image.meta?.MediaTypeValue === 'video' ||
        image.meta?.MediaTypeValue === 'youtube' ||
        image.meta?.MediaTypeValue === 'vimeo'
    "
  >
    <!-- EXTERNAL VIDEO -->
    <div
      v-if="(image.meta.MediaTypeValue === 'youtube' || image.meta.MediaTypeValue === 'vimeo') && image.meta.ExternalMediaPath"
      class="video-iframe-container"
    >
      <iframe
        class="h-full w-full object-cover"
        :class="cssClass"
        :src="(image.meta.MediaTypeValue === 'youtube' ? 'https://www.youtube.com/embed/' : 'https://player.vimeo.com/video/') + image.meta.ExternalMediaPath"
        frameborder="0"
        autoplay="0"
        loop="0"
        mute="1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
    <!-- INLINE VIDEO -->
    <video
      v-if="image.meta.MediaPath && !image.meta.ExternalMediaPath"
      width="100%"
      height="100%"
      :controls="control"
      :poster="poster"
      class="h-full w-full object-cover"
      muted
      loop
      :autoplay="autoplay"
    >
      <source
        :src="image.meta.MediaPath"
        type="video/mp4"
      >
      <source
        :src="image.meta.MediaPath"
        type="video/ogg"
      >
      Your browser does not support the video tag.
    </video>
  </template>

  <!-- else -->
  <figure
    v-else
    class="w-full h-full"
  >
    <picture>
      <source
        v-if="image.lg"
        :srcset="lazyImgClass === 'no-lazy-image' ? encodeURI(image.lg.path) : ''"
        :data-srcset="image.lg.path"
        media="(min-width: 768px)"
        :width="image.lg.width"
        :height="image.lg.height"
        :data-class="cssClass"
        :class="'lazy-source' + (lazyImgClass === 'no-lazy-image' ? ' lazy-loaded' : '')"
        :style="image.lg.style + imageStyle"
      >
      <img
        v-if="image.sm"
        :src="lazyImgClass === 'no-lazy-image' ? image.sm.path : ''"
        :data-src="encodeURI(image.sm.path)"
        class="w-full h-full object-cover transition-transform duration-300"
        :class="lazyImgClass + ' ' + cssClass"
        :width="image.sm.width"
        :height="image.sm.height"
        :alt="image.meta?.MediaAltText"
        :importance="priorityLevel"
        :style="image.lg.style + imageStyle"
      >
    </picture>

    <figcaption class>
      <h2
        v-if="title"
        class="headline-3b absolute left-0 bottom-0 top-auto my-2 mx-3"
      >
        {{ title }}
      </h2>
      <i
        v-if="tooltipDataExist()"
        v-tooltip.top-end="{
          content: tooltipContent,
          trigger: 'click hover',
          classes: 'tooltip-content tooltip-image',
        }"
        @click.stop.prevent
        class="icon icon-camera-ico text-white absolute z-20 right-0 left-auto bottom-0 opacity-100 text-24 cursor-pointer transition-all duration-300 ease-in-out m-2"
        :class="{'hidden opacity-0' : hideTooltip === true}"
      />
    </figcaption>
  </figure>
</template>

<script>
import "@/css/modules/_tooltip.scss";
import app from "@/main";


export default {
  name: "VuePicture",

  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    control: {
      type: Boolean,
      default: false,
    },
    poster: {
      type: String,
      default: "",
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    lazyImgClass: {
      type: String,
      default: "lazy-img",
    },
    imageStyle: {
      type: String,
      default: "",
    },
    priorityLevel: {
      type: String,
      default: "auto",
    }
  },
  data() {
    return {
      tooltipContent: this.setTooltipContent()
    };
  },
  computed: {
    getLang(){
        return this.$store.state.resetLanguage;
    },
  },
  watch:{
    getLang() {
      this.tooltipContent = this.setTooltipContent();
    }
  },
  methods: {
    setTooltipContent(){
      let tooltipContent = [
       `${this.image.meta.MediaDescription? "<i>" + this.image.meta.MediaDescription + "</i><br>": ""}
        ${this.image.meta.Author?.EntityID != '' ? "<span>"+ this.$t("loc_photo") +": <span>" + this.image.meta.Author.AuthorName +" " +this.image.meta.Author.AuthorSurname +"</span></span><br>": ""}
        ${this.image.meta.MediaLocation ? "<span>"+ this.$t(this.$filters.stringFormat("loc_location"))+": <span>" + this.image.meta.MediaLocation + "</span></span>": ""}`
      ];
      return this.tooltipDataExist() ? tooltipContent : [];
    },
    tooltipDataExist() {
      return (
        this.image.meta !== null &&
        (this.image.meta.MediaDescription?.length > 0 ||
          this.image.meta.Author?.EntityID !== '' ||
          this.image.meta.MediaLocation?.length > 0)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .lazy-image{
    @apply opacity-0 transition-all duration-1000 ease-in-out;
  }
  .lazy-source, .lazy-source ~ img{
    @apply opacity-0 transition-all duration-1000 ease-in-out;
  }
  .lazy-loaded, .lazy-loaded ~ img{
    opacity: 1;
  }


  .video-iframe-container{
     position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe, embed, object{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  }
  .gallery-thumbs-swiper{
    .video-iframe-container{
        height: 100%;
    }
  }
  img.img-with-border{
        border: solid 1px #e2e2e2;
    }
</style>
