<template>
  <div
    v-if="menuItem.Media.length > 0"
    :class="classMenu"
  >
    <ModuleLink
      v-if="$store.state.AdminMode"
      :module-properties="menuItem"
      :show-only-icon="true"
    />
    <MenuItem v-slot="{ active }">
      <VirtualLink
        v-if="menuItem.SefUrl || menuItem.CustomUrl"
        :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
        :class-list="anchorClass"
        :close-menu-on-click="true"
        :target="menuItem.Target ? '_' + $filters.lowercase(menuItem.Target) : ''"
      >
        <vue-picture
          v-if="getMediaItemPath(menuItem, mediaType).MediaGalleryToShow.length > 0"
          :key="getMediaItemPath(menuItem, mediaType).EntityID"
          :image="getMediaItemPath(menuItem, mediaType).MediaGalleryToShow[0]"
          :css-class="`${imgClass ? imgClass : 'menu-image'} ${active ? 'outline outline-offset-2 outline-2' : ''}`"
          :hide-tooltip="true"
          :imageStyle="active ? 'outline:2px solid #ef3524 ' : ''"
          
        />
        <h5
          v-if="showMenuName"
          class="headline-5 absolute bottom-0 left-0 p-3"
        >
          {{ menuItem.Name }}
        </h5>
      </VirtualLink>
    
    <template
      v-else
    >
      <ModuleLink
        v-if="$store.state.AdminMode"
        :module-properties="menuItem"
        :show-only-icon="true"
      />
      <vue-picture
        v-if="getMediaItemPath(menuItem, mediaType).MediaGalleryToShow.length > 0"
        :key="getMediaItemPath(menuItem, mediaType).EntityID"
        :image="getMediaItemPath(menuItem, mediaType).MediaGalleryToShow[0]"
        :css-class="imgClass ? imgClass : ''"
      />
      <h5
        v-if="showMenuName"
        class="headline-5 absolute bottom-0 left-0 p-3"
      >
        {{ menuItem.Name }}
      </h5>
    </template>
  </MenuItem>
  </div>
</template>

<script>
import VuePicture from "@/components/includes/VuePicture";
import { menuMediaMixin } from "@/mixins";
import {MenuItem} from "@headlessui/vue";

export default {
  components: {
    VuePicture,
    MenuItem
  },
  mixins: [menuMediaMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    classMenu: {
      type: String,
      default: () => (''),
    },
    showMenuName: {
      type: Boolean,
      default: () => (false),
    },
    mediaLayout: {
      type: String,
      default: () => (''),
    },
    mediaType: {
      type: String,
      default: () => (''),
    },
    anchorClass: {
      type: String,
      default: () => (''),
    },
    imgClass: {
      type: String,
      default: () => (''),
    },
  }
};
</script>
