import {Appstore} from "@/store/main.store";
import {state} from "@/store/component.store";
import {AppState, ComponentState, EntityType, ModuleInfo, RelatedInterface, ReviewData} from "@/interfaces";
import {
  CrudUserFirebase,
  DashboardDTO,
  EntityDtoAPI,
} from "@/interfaces/entity-api/entities-response-dto";
import {axiosInstance} from "@/services/http-common";
import {v4 as uuidv4} from 'uuid';
import {SelectItemEmit} from "@/libs/ui/.storybook/interfaces";
import {boolean} from "@storybook/addon-knobs";
import {dateFromString} from "@/services/date-utilis";
import {AxiosResponse} from "axios";
import firebase from "firebase";
import {LoggedInUser} from "@/interfaces/login/logged-in-user";
import { ActionContext } from "vuex";

export const API_CRUD_URL = "https://local.edit.api.local-dev/" //"http://localhost:8080/crud"; //TODO Change CRUD endpoint
export const setStoreModule = (id: string) => {

  if (!Appstore.hasModule(id)) {
    Appstore.registerModule(id, {state})
    Appstore.state[id].uuid = uuidv4()
  }

}


export function getRequestProps(moduleInfo: ModuleInfo) {
  return {
    Fields: getRequestFields(moduleInfo),
    RelatedFields: getRelatedFields(moduleInfo),
    Paging: getModulePaging(moduleInfo),
    MediaTypes: getMediaTypes(moduleInfo),
    FilterByActivePeriods: getActivePeriods(moduleInfo),
    Sort: getSortParams(moduleInfo)
  }
}
export function getSortParams(moduleInfo: ModuleInfo): {Field: string, Direction?: string}[] | undefined {
  return moduleInfo.Module.Binding.SortParam ?
    moduleInfo.Module.Binding.SortParam.split(', ').map(sortParam => (
      {
        Field: sortParam.replace('DESC', '').replace('ASC', '').trim(),
        ...(sortParam.includes('DESC') ? {Direction: 'DESC'} : {}),
        ...(sortParam.includes('ASC') ? {Direction: 'ASC'} : {})
      })) :
    //[{Filed: moduleInfo.Module.Binding.SortParam,}] :
    undefined
}
export function setPublicationPlace<T extends  {Domain?: string, SefUrl?: string}>(one: T): T {
  if (one.Domain && one.SefUrl) {
    one.SefUrl = (one.Domain.endsWith('/') ? one.Domain : one.Domain + '/') + (one.SefUrl.startsWith('/') ? one.SefUrl.slice(1) : one.SefUrl)
  }
  return one
}
export function getActivePeriods({Module: {ModuleType, ModuleVariation}}: ModuleInfo): boolean {
  return ModuleVariation.ModuleRequiredActivePeriod
}
export function getRequestFields(moduleInfo: ModuleInfo): string[] | undefined {
  return [
    ...new Set([
      'SefUrl',
      ...moduleInfo.Module.ModuleDynamic?.Fields,
      ...JSON.parse(moduleInfo.Module.ModuleType?.Fields || '[]') as string[],
      ...JSON.parse(moduleInfo.Module.ModuleVariation?.Fields || '[]') as string[]
      ])
  ]
    .filter(o => moduleInfo.Module.SourceFields?.indexOf(o) > -1)
}
export interface Paging {
  Offset: number;
  Limit?: number;
  Total?: number;
}

export function isModuleQueryDependant(moduleInfo: ModuleInfo): boolean {
  return true
}
export function getLookupSourceFromField(moduleInfo: ModuleInfo, param: string): string {
  return moduleInfo.Module.ModuleFilter!.find(o => o.FilterField === param)!.LookupSource
}
export function moduleIncludeQuery(moduleInfo: ModuleInfo, param: string): boolean {
  return isModuleQueryDependant(moduleInfo) &&
    (
      !!moduleInfo.Module.ModuleFilter?.some(o => o.FilterField?.toLowerCase() === param.toLowerCase() || (param.toLowerCase().includes('date') && o.FilterField?.toLowerCase().includes('date')))
        ||
      !!moduleInfo.Module.SourceRelatedFields?.some(o => {
        return (
          o.RelatedEntityTypeName === 'Persona' && param === 'Persona')
          ||
          (o.RelatedEntityTypeName === 'Product' && param === 'Product')
      })

    )
}
export function moduleIncludeQueryType(moduleInfo: ModuleInfo, param: string, type: string): boolean {
  return isModuleQueryDependant(moduleInfo) &&
    !!moduleInfo.Module.ModuleFilter?.some(o => o.FilterType === type && o.FilterField.toLowerCase() === param.toLowerCase())
}
export function getMediaTypes({Module: {ModuleType, ModuleVariation}}: ModuleInfo): string[] {
  const temp = [...new Set(
    [
      ...(JSON.parse(ModuleType.MediaTypeName) as string []),
      ...(JSON.parse(ModuleVariation.MediaTypeName) as string [])
    ]
  )].filter(o => o !== '')
  // return temp.includes('default') ? temp : [...temp, 'default']
  return temp
}
export function getModuleFilters({Module: {EntityID}}: ModuleInfo, state: AppState): {AND?: any[], OR?: any[]} | undefined {
  const storeModuleInfo = (state[EntityID] as ComponentState)
  const and = [...(storeModuleInfo.editFilter.and ?? []), ...(state[EntityID]?.filters as SelectItemEmit[]).filter(o => !!o.value).map(o => {
    //console.log({o});
    switch (o.type) {
      case 'date':
        const field: string = storeModuleInfo.moduleInfo?.Module.ModuleFilter?.find(o => o.FilterField.toLowerCase().includes('date'))?.FilterField || ''
        const tempDate = dateFromString(o.value as string)
        if (o.property.toLowerCase().includes('startdate') && field) {
          return [{
            [field]: {
              gte: (new Date(tempDate.getTime() - (tempDate.getTimezoneOffset() * 60000))).toISOString().slice(0,10)
            }
        }]
        } else {
          return [{
            [field]: {
              lte: (new Date(tempDate.getTime() - (tempDate.getTimezoneOffset() * 60000))).toISOString().slice(0,10)
            }
        }]
        }

      case 'checkboxlist':
        return [{
          [o.property]: {
            in: o.value!.toString()
          }
        }]
      case 'input':
        return [{
          [o.property]: {
            ilike: '%' + o.value!.toString() + '%'
          }
        }]
        default:
          if(o.value!.toString()?.split('|')?.length > 1) {
            return ([{[o.property]: {in: o.value!.toString().replace(/\|/g, ',')}}])
          } else {
            return ([{[o.property]: {is: o.value!.toString()}}])
          }
    }

  })];
  const or = [...(storeModuleInfo.editFilter.or ?? [])]
  return (and.length || or.length) ? {
    ...(and.length && {AND: and}),
    ...(or.length && {OR: or}),
  } :
    undefined
}
export function getModulePaging({Module: {Paging: {Offset, DataLimit, ItemPerPage}}}: ModuleInfo): Paging {
 return {
   Offset: Offset ? +Offset : 0,
   Limit: ItemPerPage ? +ItemPerPage : DataLimit ? +DataLimit : undefined,
   Total: DataLimit ? +DataLimit : undefined,
 }
}
export function removeDuplicateFromTwoArrays(arr1: string[], arr2: string[]): string[] {
  return arr1.concat(arr2.filter((item) => arr1.indexOf(item) < 0))
}

export function getJsonData(json: string, defaultValue: any):any {
  let data
  if (!json) {return defaultValue}
  try {
    data = JSON.parse(json)
  } catch (e) {
    console.error('invalid JSON in module Type')
    data = defaultValue
  }
  return data
}
/**
 * spaja modul tip i varijaciju related fields i provjerava sa binding dali postoje
 * @param {ModuleInfo} moduleInfo
 * @returns {{[p: string]: string} | undefined}
 */
export function getRelatedFields(moduleInfo: ModuleInfo): {[key: string]: string} | undefined {
  const temp: {[key: string]: string[]} = getJsonData(moduleInfo.Module.ModuleType?.RelatedFields, {});
  const temp2: {[key: string]: string[]} = getJsonData(moduleInfo.Module.ModuleVariation?.RelatedFields,{})
  const temp3: {[key: string]: string[]} = moduleInfo.Module.ModuleDynamic.RelatedFields
  const tempToReturn: {[key: string]: string} = {}
  const relatedBinding: {[key: string]: any} = (moduleInfo.Module.SourceRelatedFields ?? []).reduce((o,n) => ({...o, [n.RelatedEntityTypeName]: n}), {})

  // spoiti sve iz varijacije u tip
  Object.entries(temp2).forEach(([a, b]) => {
    temp[a] = !!temp[a] ? removeDuplicateFromTwoArrays(temp[a], b) : b
  })
  // spoiti sve iz varijacije u tip
  Object.entries(temp3).forEach(([a, b]) => {
    temp[a] = !!temp[a] ? removeDuplicateFromTwoArrays(temp[a], b) : b
  })
  //provjeriti ako postoi u binding
  Object.entries(temp).forEach(([a,b]) => {

    if (relatedBinding[a]) {
      const values = b.filter(o => relatedBinding[a].RelatedEntityFields.includes(o))
      if (values.length) {
        tempToReturn[a] = values.map(o => a + '.' + o).join(',')
      }
    }
  })
  return tempToReturn
}

export function checkFavoriteIntegrity(favorite:RelatedInterface): boolean {
  //console.log({favorite});
  if(!!favorite.EntityID && (favorite.Title || favorite.Seo.PageTitle || favorite.SEO.PageTitle) && (favorite.SefUrl || favorite.ExternalLink || favorite.Seo?.SefUrl || favorite.SEO?.SefUrl)) {
    return true
  } else {
    console.error('invalid favorites');
    return false
  }
}

export function getModuleDataKey(moduleInfo: ModuleInfo): string {
  return moduleInfo.Module.EntityID.toString();
  /*const {ModuleEntityID, ...moduleToUse} = moduleInfo.Module.Api.RequestBody
  return Object.entries(moduleToUse)
    .sort(([a,b], [c,d]) => ('' + a).localeCompare(c))
    .map(([a,b]) => b)
    .filter(o => o)
    .reduce((o,n) => o + n.toString(), '')*/
}
/*export function buildCrudEntity(
  entityId: number,
  entityName: string,
  entity: {[key: string]: {value: string | number, language: string}},
  caption: string,
  parentId = 0,
  active = true,
): EntityDtoCRUD {

  return {
    IdObjekta: 0,
    Redoslijed: 0,
    IdTipaObjekta: entityId,
    NazivTipaObjekta: entityName,
    ParentId: parentId,
    InternaOznaka: caption,
    Korisnik: "VS",
    Aktivan: active,
    Svojstva: Object.entries(entity).map(([a, b]) => ({
      NazivSvojstvaObjekta: a,
      VrijednostSvojstvaId: 0,
      Vrijednost: b.value,
      IDJezika: b.language,
      time_stamp: new Date().toISOString()
    }))
  };
}*/
export function checkReviewData( review: ReviewData): boolean {
  //console.log(review.TitleJezicnoNeovisan.value )
  //console.log(review.Comment.value )
  //console.log(review.CommentUser?.value)
  //console.log(review.Rating.value <= 5)
  return !!review.TitleJezicnoNeovisan.value && !!review.Comment.value && !!review.CommentUser?.value && review.Rating.value >=0 && review.Rating.value <= 5
}
export function getCrudUrl(state: AppState): string {
  return state.CRUDApi.url || '';
  // return state.Params.find(o => o.Key === 'CRUD_API_Url')?.Value ?? '';
}
export function getCrudUsername(state: AppState): string {
  return 'cru' + 'd_ap' + 'i_use' + 'rname'
  //return state.Params.find(o => o.Key === 'key')?.Value ?? '';
}
export function getCrudPassword(state: AppState): string {
  return 'c1' + 'ud_3p1_u3' + 'e3n3m3'
  //return state.Params.find(o => o.Key === 'key')?.Value ?? '';
}

/*
export function syncEntityCrud( state: AppState) {
  const url = getCrudUrl(state);
  const username = getCrudUsername(state);
  const password = getCrudPassword(state);

  return axiosInstance.post<EntityDtoCRUD>(url +( url.endsWith('/') ? '' : '/')  + "sync/entity", {
    "SyncIDs": [
      {
        "IdObjekta": +(state.LoggedInUserModule?.editUserId || "0")
      }
    ]
  }, {
    //withCredentials: true,
    auth: {
      username,
      password
    }
  })
}
export function crudCreateNew(entityCrud: EntityDtoCRUD, state: AppState) {
  const url = getCrudUrl(state);
  const username = getCrudUsername(state);
  const password = getCrudPassword(state);
  return axiosInstance.post<{ entity: EntityDtoCRUD }>(url + (url.endsWith('/') ? '' : '/' )+ "entity", entityCrud, {
    //withCredentials: true,
    auth: {
      username,
      password
    }
  })
}
export function crudGetOne( state: AppState) {
  const url = getCrudUrl(state);
  const username = getCrudUsername(state);
  const password = getCrudPassword(state);
  return axiosInstance.get<EntityDtoCRUD>(url +( url.endsWith('/') ? '' : '/')  + "entity/" + state.LoggedInUserModule?.editUserId ?? 'noID', {
    //withCredentials: true,
    auth: {
      username,
      password
    }
  })
}
export function crudUpdateOne(entityCrud: EntityDtoCRUD, state: AppState) {
  let url = getCrudUrl(state);

  const username = getCrudUsername(state);
  const password = getCrudPassword(state);
  console.log((url.endsWith('/') ? '' : '/'));
  console.log((url + (url.endsWith('/') ? '' : '/') + "entity/"));
  return axiosInstance.put<{ entity: EntityDtoCRUD }>(url +( url.endsWith('/') ? '' : '/') + "entity/" + state.LoggedInUserModule?.editUserId ?? 'noID', entityCrud, {
    //withCredentials: true,
    auth: {
      username,
      password
    }
  })
}
*/

export function findEntityName(id: number, entityTypes: EntityType[]): string {
  return entityTypes.find(o => o.EntityTypeID === id)?.EntityName ?? ''
}

export function getHpBreadcrumbs(store: ActionContext<AppState, AppState>, counter: number) {
  const homePageObjectId = parseInt(store.state.Params.find(o => o.Key === 'HomePageObjectId')?.Value || '', 10);
  if(homePageObjectId > 0) {
    axiosInstance
      .post("/api/breadcrumbs", {
        Settings: store.state.CdnApiKey,
        EntityID: homePageObjectId,
        LanguageID: store.state.PageData.LanguageId,
      })
      .then((response) => {
        store.state.HpHyperLink = response.data.length > 0 ? response.data[0] : null;
      })
      return true;
  } else if(counter === 2) {
    return true;
  }
  return false;
}
