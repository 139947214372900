<template>
  <div
    class="nav-height-container"
    :class="{
      'has-covid-track':
        filterMenu('COVID_TRACK').length > 0 &&
        (showCovidTrack === true || showCovidTrack === null),
    }"
  >
    <!-- COVID TRAKA -->
    <template
      v-for="menuItem in filterMenu('COVID_TRACK')"
      :key="menuItem.EntityID"
    >
      <NavCovidTrack :menu-item="menuItem" @showCovid="toggleCovidTrack" />
    </template>
    <div class="nav-container max-w-screen-3xl mx-auto bg-white">
      <nav class="nav container max-w-screen-2xl mx-auto bg-white flex items-center justify-between relative">
        <!-- add class .open to make it into an X -->
        <NavDropdownMobileMenu
          v-if="gotMenuData.length > 0 && display === 'mobile'"
        />
        <!-- <div class="gcse-search"></div> -->

        <div class="nav-logo-and-list-container w-full flex-row-reverse lg:flex-row flex items-center mr-2">
          <div class="logo-container space-x-2 md:space-x-4 xl:space-x-5 relative z-0 flex items-center mr-6 xl:mr-0 lg:min-w-150 xl:min-w-200">
            <Logo
              v-for="menuItem in filterMenu('LOGO')"
              :key="menuItem.EntityID"
              :anchor-class="'logo'"
              :img-class="'w-full h-full'"
              :menu-item="menuItem"
            />
          </div>

          <ul
            v-if="gotMenuData.length > 0"
            class="nav-list-main hidden lg:flex lg:space-x-2 xl:ml-2 2xl:ml-0"
          >
            <template v-for="menuItem in filterMenu('HEADER_MENU')">
              <li
                v-if="menuItem.Children.length || menuItem.SefUrl || menuItem.CustomUrl"
                :key="menuItem.EntityID"
              >
                <MenuContainer
                  v-if="menuItem.Children.length || menuItem.SefUrl || menuItem.CustomUrl"
                  :module-info="menuItem"
                  :class="{ 'link-only': menuItem.Children.length < 1 }"
                />
              </li>
            </template>
          </ul>
          <div class="sticker-container flex lg:hidden h-full pl-4">
            <!-- <img src="dist/img/safe-stay.jpg"> -->
            <Logo
              v-for="menuItem in filterMenu('STICKER')"
              :key="menuItem.EntityID"
              :anchor-class="'sticker'"
              :img-class="'sticker-img w-full h-full'"
              :menu-item="menuItem"
            />
          </div>
        </div>
        <div class="flex z-50">
          <div class="sticker-container hidden lg:flex h-full">
            <!-- <img src="dist/img/safe-stay.jpg"> -->
            <Logo
              v-for="menuItem in filterMenu('STICKER')"
              :key="menuItem.EntityID"
              :anchor-class="'sticker'"
              :img-class="'sticker-img w-full h-full'"
              :menu-item="menuItem"
            />
          </div>
          <div class="nav-icons hidden lg:flex justify-center items-center">
            <FirebaseLoginWrapper />
            <UserAccessibilityDropdown />
            <!--TODO: zakomentirano dok se ne implementira -->
            <MenuIcon
              v-for="menuItem in filterMenu('MENU_ICON')"
              :key="menuItem.EntityID"
              :anchor-class="''"
              :menu-item="menuItem"
            />
            <NavIcon :has-covid-track="showCovidTrack" />
            <!--TODO: zakomentirano dok se ne implementira -->
            <!-- <i class="icon icon-cart-ico text-htz-steel hover:text-htz-red text-40" /> -->
            <SearchModal :has-covid-track="showCovidTrack"
                         :google-search-url="googleSearchUrl"/>
            <!--AUTH MODAL LG TODO: prebaciti na pravo mjesto-->
            <AuthModal :has-covid-track="showCovidTrack" />

            <UserAccountDropdown />
          </div>
          <div class="flex items-center">
            <SelectItemLang class="text-htz-steel lg:text-htz-darkgrey" />
            <div class="icon-searc-container cursor-pointer flex lg:hidden">
              <SearchModal :has-covid-track="showCovidTrack" :google-search-url="googleSearchUrl"/>
              <!--AUTH MODAL SM TODO: prebaciti na pravo mjesto-->
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- <vue-final-modal v-model="showModal">
  Modal Content Here
</vue-final-modal>

<button @click="showModal = true">Launch</button> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
//import vSelect from 'vue-select';
import "@/css/modules/_navigation.scss";
import SelectItemLang from "@/components/includes/selects/SelectItemLang.vue";
import UserAccountDropdown from "@/components/includes/dropdowns/UserAccountDropdown";
import SearchModal from "@/components/includes/modals/SearchModal";
import AuthModal from "@/components/includes/modals/AuthModal";
import UserAccessibilityDropdown from "@/components/includes/dropdowns/UserAccessibilityDropdown";
import NavDropdownMobileMenu from "@/components/includes/dropdowns/NavDropdownMobileMenu";
import NavCovidTrack from "@/components/includes/dropdowns/NavCovidTrack";
import MenuContainer from "@/components/includes/MenuContainer";
import { menuMixin } from "@/mixins";
import Logo from "./Logo";
//import LoginButton from "@/components/includes/login/LoginButton";
import FirebaseLoginWrapper from "@/components/includes/login/FirebaseLoginWrapper";
import MenuIcon from "./MenuIcon.vue";

export default {
  name: "Navigation",
  components: {
    SelectItemLang,
    UserAccountDropdown,
    UserAccessibilityDropdown,
    NavDropdownMobileMenu,
    NavCovidTrack,
    Logo,
    MenuContainer,
    SearchModal,
    AuthModal,
    //LoginButton,
    FirebaseLoginWrapper,
    MenuIcon
},
  mixins: [menuMixin],
  data() {
    return {
      attributes: {
        ref: "openIndicator",
        role: "presentation",
      },
      options: [
        {
          id: 1,
          name: "English",
          short: "EN",
        },
        {
          id: 2,
          name: "Italian",
          short: "IT",
        },
        {
          id: 3,
          name: "Croatian",
          short: "HR",
        },
      ],
      loading: false,
      selected: "EN",
      users: [],
      menuMain: {},
      flag: false,
      display: "desktop",
      showCovidTrack: false// sessionStorage.getItem("showCovidTrack"),
    };
  },
  computed: {
    ...mapState("LoggedInUserModule", {
      userUid: "uid",
      userDisplayName: "displayName",
      userEmail: "email",
    }),
    googleSearchUrl() {
      const temp = this.$store.state.Params.find(o => o.Key === 'GoogleSearchUrl_' + this.$store.state.LanguageId)
      return temp?.Value ? temp : this.$store.state.Params.find(o => o.Key === 'GoogleSearchUrl')
    }
  },
  mounted() {
    this.windowWidth();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.windowWidth);
  },
  methods: {
    login: function () {
      this.$store.commit("LoggedInUserModule/startLogin");
    },
    register: function () {
      this.$store.commit("LoggedInUserModule/startRegistration");
    },
    logout: function () {
      this.$store.dispatch("LoggedInUserModule/logout");
    },
    search: function (queryString) {
      this.loading = true;
      // TODO remove this request
      fetch("https://jsonplaceholder.typicode.com/users")
        .then((response) => response.json())
        .then((json) => {
          this.loading = false;
          if (queryString) {
            this.users = json.filter((user) =>
              user.name.toLowerCase().includes(queryString.toLowerCase())
            );
          } else {
            this.users = json;
          }
        })
        .catch((e) => {
          console.error(e);
          this.loading = false;
        });
    },
    //sticky navigation
    handleScroll() {
      const body = document.body;
      const nav = document.querySelector(".nav-container");
      const tabNav = document.querySelector(".nav-container");
      const navTop = nav.offsetHeight;

      //console.log('navTop = ' + navTop);
      //console.log('scrollY = ' + window.scrollY);

      if (window.scrollY >= navTop) {
        nav.classList.add("nav-fixed-container");
        body.classList.add("navigation-is-fixed");
        if (tabNav) {
          tabNav.classList.add("tab-nav-fixed-upper");
        }
      } else {
        nav.classList.remove("nav-fixed-container");
        body.classList.remove("navigation-is-fixed");

        if (tabNav) {
          tabNav.classList.remove("tab-nav-fixed-upper");
        }
      }
    },
    windowWidth() {
      if (window.innerWidth > 992) {
        this.display = "desktop";
      } else {
        this.display = "mobile";
        if (this.flag === false) {
          this.flag = true;
        }
      }
    },
    toggleCovidTrack(e) {
      this.showCovidTrack = e
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-searc-container:hover {
  .icon-search-ico:before {
    @apply text-htz-red;
  }
}
.logo {
  &:focus-visible {
    transition: none !important;
  }
}
.sticker-container {
  @apply items-center top-0 left-0 right-auto;
  // top: 3px;
  // left: 78px;
  max-width: 60px;
  @screen lg {
    @apply relative top-auto left-auto;
    max-width: initial;
  }
}
</style>
