<template>
  <div
    v-if="moduleInfo.Children.length > 0"
    class="nav-dropdown max-w-screen-lg"
  >
    <div class="nav-dropdown-container nav-dropdown-container-with-images max-w-screen-md">
      <template
        v-for="menuItem in moduleInfo.Children.slice(0, 1)"
        :key="menuItem.EntityID"
      >
        <ModuleLink
          v-if="$store.state.AdminMode"
          :module-properties="menuItem"
          :show-only-icon="true"
        />
        <h3
          v-if="menuItem.SefUrl || menuItem.CustomUrl"
          class="nav-dropdown-group-title relative z-1"
        >
          <MenuItem v-slot="{ active }">
            <VirtualLink
              :close-menu-on-click="true"
              :href="menuItem.CustomUrl ? menuItem.CustomUrl : menuItem.SefUrl"
              :target="menuItem.Target"
            >
              <span :class="active ? 'text-htz-red' : 'text-htz-darkgrey'">
                {{ menuItem.Name }}
              </span>
            </VirtualLink>
          </MenuItem>
        </h3>
        <h3
          v-else
          class="relative z-1"
        >
          {{ menuItem.Name }}
        </h3>
        <div
          v-if="menuItem.Children.length > 0"
          class="w-full max-w-740 grid gap-3 lg:gap-0 grid-cols-2 lg:flex lg:flex-no-wrap justify-between border-b border-htz-lightgrey pb-6"
        >

          <template v-for="menuItemChild in menuItem.Children"
                    :key="menuItemChild.EntityID">
            <div>
              <div class="h-full" :class="active ? 'border-2 border-htz-red' : 'border-2 border-transparent'">
                <MenuImagesLink

                  :menu-item="menuItemChild"
                  :class-menu="'relative h-full'"
                  :show-menu-name="true"
                  :media-layout="'lg'"
                  :media-type="'menuImage'"
                />
              </div>
            </div>
          </template>

        </div>
      </template>

      <!--<div class="w-full flex items-start justify-between py-6">-->
      <div class="w-full grid gap-x-6 gap-y-8 lg:gap-0 grid-cols-2 lg:flex lg:flex-wrap lg:mt-10">
        <div
          v-for="menuItem in moduleInfo.Children.slice(1, moduleInfo.Children.length)"
          :key="menuItem.EntityID"
          class="w-full lg:w-1/3 lg:pb-8 lg:pr-4"
        >
          <!-- Dodana klasa w-1/4 pb-8 (za više od 4 kolone da stavke idu u drugi red) -->
          <NavMenuItemOption :menu-item="menuItem" />
        </div>
      </div>

      <div
        v-if="moduleInfo.SefUrl || moduleInfo.CustomUrl"
        class="pt-2"
      >
        <MenuItem
          :as="VirtualLink" v-slot="{ active }"
          :href="moduleInfo.CustomUrl ? moduleInfo.CustomUrl : moduleInfo.SefUrl"
          class-list="btn-red-border"
          :target="moduleInfo.Target"
          :close-menu-on-click="true"
        >
          <span :class="active ? 'text-htz-red' : 'text-htz-darkgrey'">
            {{ $t("loc_explore_more") }}
          </span>
        </MenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import NavMenuItemOption from "@/components/includes/dropdowns/NavMenuItemOption";
import { menuMediaMixin } from "@/mixins";
import MenuImagesLink from "@/components/includes/MenuImagesLink";
import {lazyLoad} from "@/components/utils/lazyLoadImages";
import {MenuItem} from "@headlessui/vue";
import VirtualLink from "@/components/includes/router/VirtualLink";

export default {
  components: {
    NavMenuItemOption,
    MenuImagesLink,
    MenuItem
  },
  mixins: [menuMediaMixin],
  props: {
    moduleInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      VirtualLink,
      loggedIn: false,
    };
  },
  mounted() {
     setTimeout(() => {
        lazyLoad();
      }, 1000)
  },
};
</script>
<style lang="scss" scoped>
button:focus{outline:none;}
</style>
